import React, { useEffect } from 'react';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Slide, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { IServiceOrders, ServiceOrderType } from '../../../../store/interfaces/PreventableMaintenance/service.orders';
import FileInput from '../../../../compliancemasters/partials/modals/new-file/components/FileInput';
import {
    useCreateServiceOrderMutation,
    useUpdateServiceOrderMutation
} from '../../../../store/reducers/PreventableMaintenance/service.orders';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import axios from 'axios';
import { IServiceAlerts } from '../../../../store/interfaces/ServiceAlerts/ServiceAlerts.interface';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    vehicle?: IMaintenanceVehicle;
    serviceAlert?: IServiceAlerts;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues: Partial<IServiceOrders> = {
    maintenance_vehicle: undefined,
    description: '',
    title: '',
    file_name: '',
    original_file_name: '',
    file_type: '',
    service_alert: undefined,
    service_order_type: ServiceOrderType.DVIR_REPORT,
    odometer_reading: 0
};

export const AddServiceOrderModal: React.FunctionComponent<IProps> = ({ open, setOpen, vehicle, serviceAlert }) => {
    const [file, setFile] = React.useState<File>();
    const [loading, setLoading] = React.useState(false);
    const [uploaded, setUploaded] = React.useState(false);
    const [createServiceOrder] = useCreateServiceOrderMutation();
    const [updateServiceOrder] = useUpdateServiceOrderMutation();
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            description: Yup.string().required('Description is required'),
            title: Yup.string().required('Title is required')
        }),
        onSubmit: (values) => {
            createServiceOrder({
                file_name: file ? file.name : '',
                file_type: file ? file.type : '',
                description: values.description,
                title: values.title,
                maintenance_vehicle: vehicle,
                service_alert: serviceAlert,
                service_order_type: values.service_order_type,
                odometer_reading: values.odometer_reading
            }).then((res: { data?: { url: string; id: string }; error?: FetchBaseQueryError | SerializedError }) => {
                if (res.error) {
                    setOpen(false);
                    formik.resetForm();
                }
                if (res.data) {
                    const options = {
                        headers: {
                            'Content-Type': (file as File).type
                        }
                    };

                    const instance = axios.create();
                    instance.put(res.data.url, file, options).then(() => {
                        updateServiceOrder({ id: Number(res?.data?.id) })
                            .then(() => {
                                setUploaded(true);
                                setLoading(false);
                                formik.resetForm();
                                setFile(undefined);
                                setOpen(false);
                            })
                            .catch((err: any) => {
                                setLoading(false);
                                setUploaded(false);
                                console.log(err);
                                setFile(undefined);
                            });
                    });
                }
            });
        }
    });

    useEffect(() => {
        if (open) {
            formik.resetForm();
            setUploaded(false);
            setLoading(false);
            setFile(undefined);
        }
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    padding: '20px 20px 20px 20px'
                }}
            >
                {serviceAlert
                    ? `Add Service Order for ${serviceAlert.service.name} on Vehicle ${serviceAlert.vehicle.vehicle_number}`
                    : 'Add Service Order'}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} p={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="title"
                                name="title"
                                label="Title"
                                size="small"
                                value={formik.values.title}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Service Order Type"
                                select
                                value={formik.values.service_order_type}
                            >
                                {Object.values(ServiceOrderType).map((option) => (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                        onClick={() => formik.setFieldValue('service_order_type', option)}
                                    >
                                        {option === ServiceOrderType.DVIR_REPORT ? 'DVIR Report' : 'Miscellaneous'}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {formik.values.service_order_type === ServiceOrderType.DVIR_REPORT && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="odometer_reading"
                                    name="odometer_reading"
                                    label="Vehicle Odometer Reading"
                                    size="small"
                                    value={formik.values.odometer_reading}
                                    error={formik.touched.odometer_reading && Boolean(formik.errors.odometer_reading)}
                                    helperText={formik.touched.odometer_reading && formik.errors.odometer_reading}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="description"
                                name="description"
                                label="Description"
                                multiline
                                rows={4}
                                size="small"
                                value={formik.values.description}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item sm={12} lg={12}>
                            <FileInput
                                onChange={(e) => {
                                    setFile(e.target.files?.[0]);
                                }}
                                onClear={() => setFile(undefined)}
                                value={file}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={loading || uploaded}
                                type="submit"
                                variant="contained"
                                size="small"
                                fullWidth
                            >
                                Add Service Order
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};
