import {
    Box,
    Button,
    Grid,
    MenuItem,
    Paper,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TextField
} from '@mui/material';
import React, { FC } from 'react';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import { IServiceAlerts } from '../../../store/interfaces/ServiceAlerts/ServiceAlerts.interface';
import { IMaintenanceVehicle } from '../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { IDVIReport } from '../../../store/interfaces/PreventableMaintenance/dvir.report';
import { AddServiceOrderModal } from './MaintenanceVehicleDetails/AddServiceOrderModal';

interface IProps {
    serviceAlerts: IServiceAlerts[];
}

export enum Filter {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    SERVICE_ALERT = 'SERVICE_ALERT',
    PRE_SERVICE_ALERT = 'PRE_SERVICE_ALERT',
    ALL = 'ALL'
}

const ServiceAlertsTable: FC<IProps> = ({ serviceAlerts }) => {
    const [page, setPage] = React.useState<number>(0);
    const [limit, setLimit] = React.useState<number>(10);
    const [filter, setFilter] = React.useState<Filter>(Filter.ACTIVE);
    const [alerts, setAlerts] = React.useState<IServiceAlerts[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [vehicle, setVehicle] = React.useState<IMaintenanceVehicle>();
    const [serviceAlert, setServiceAlert] = React.useState<IServiceAlerts>();
    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    React.useEffect(() => {
        switch (filter) {
            case Filter.SERVICE_ALERT:
                setAlerts(serviceAlerts.filter((alert) => alert.alert_type === 'SERVICE_ALERT'));
                break;
            case Filter.PRE_SERVICE_ALERT:
                setAlerts(serviceAlerts.filter((alert) => alert.alert_type === 'PRE_SERVICE_ALERT'));
                break;
            case Filter.ACTIVE:
                setAlerts(serviceAlerts.filter((alert) => alert.is_active));
                break;
            case Filter.INACTIVE:
                setAlerts(serviceAlerts.filter((alert) => !alert.is_active));
                break;
            default:
                setAlerts(serviceAlerts);
                break;
        }
    }, [filter, serviceAlerts]);

    return (
        <Paper component={Box} mt={4}>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Service Alerts" />
                </TableContainerHeader>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            fullWidth
                            value={filter}
                            variant="outlined"
                            size="small"
                            select
                            helperText="Filter by alert type"
                        >
                            {Object.values(Filter).map((option: Filter) => (
                                <MenuItem key={option} value={option} onClick={() => setFilter(option)}>
                                    {option === Filter.ACTIVE
                                        ? 'Active Service Alerts'
                                        : option === Filter.INACTIVE
                                        ? 'Past Service Alerts'
                                        : option === Filter.PRE_SERVICE_ALERT
                                        ? 'Pre Service Alerts'
                                        : 'All Service Alerts'}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>#</TableHeaderColumn>
                        <TableHeaderColumn>Vehicle No.</TableHeaderColumn>
                        <TableHeaderColumn>Vehicle Type</TableHeaderColumn>
                        <TableHeaderColumn>Service</TableHeaderColumn>
                        <TableHeaderColumn>Alert Type</TableHeaderColumn>
                        <TableHeaderColumn>Description</TableHeaderColumn>
                        <TableHeaderColumn>Start Odo Reading</TableHeaderColumn>
                        <TableHeaderColumn>End Odo Reading</TableHeaderColumn>
                        <TableHeaderColumn>Diff</TableHeaderColumn>
                        <TableHeaderColumn>Reminder Date</TableHeaderColumn>
                        <TableHeaderColumn>Action</TableHeaderColumn>
                    </TableHeader>
                    <TableBody>
                        {alerts.slice(page * limit, page * limit + limit).map((serviceAlert, index) => (
                            <TableRow key={serviceAlert.id} sx={{ cursor: 'pointer' }} hover>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{serviceAlert?.vehicle.vehicle_number}</TableCell>
                                <TableCell>{serviceAlert?.vehicle.vehicle_type}</TableCell>
                                <TableCell>{serviceAlert?.service.name}</TableCell>
                                <TableCell>
                                    {serviceAlert?.alert_type === 'SERVICE_ALERT'
                                        ? 'Service Alert'
                                        : 'Pre Service Alert'}
                                </TableCell>
                                <TableCell>{serviceAlert.description}</TableCell>
                                <TableCell>
                                    {Intl.NumberFormat('en-US', {
                                        style: 'unit',
                                        unit: 'mile'
                                    }).format(Number(serviceAlert.dvir_report.start_odometer_reading))}
                                </TableCell>
                                <TableCell>
                                    {Intl.NumberFormat('en-US', {
                                        style: 'unit',
                                        unit: 'mile'
                                    }).format(Number(serviceAlert.dvir_report.end_odometer_reading))}
                                </TableCell>
                                <TableCell>
                                    {Intl.NumberFormat('en-US', {
                                        style: 'unit',
                                        unit: 'mile'
                                    }).format(
                                        Number(serviceAlert.dvir_report.end_odometer_reading) -
                                            Number(serviceAlert.dvir_report.start_odometer_reading)
                                    )}
                                </TableCell>
                                <TableCell>
                                    {Intl.DateTimeFormat('en-US', {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: '2-digit'
                                    }).format(new Date(serviceAlert.date_of_reminder))}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        disabled={!serviceAlert.is_active}
                                        onClick={() => {
                                            setVehicle(serviceAlert.vehicle);
                                            setServiceAlert(serviceAlert);
                                            setOpen(true);
                                        }}
                                    >
                                        <Box>Mark Complete</Box>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>
                <Box p={2}>
                    <TablePagination
                        component="div"
                        count={alerts.length}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[10, 25, 30]}
                    />
                </Box>
            </TableWrapper>
            <AddServiceOrderModal open={open} setOpen={setOpen} vehicle={vehicle} serviceAlert={serviceAlert} />
        </Paper>
    );
};

export default ServiceAlertsTable;
